body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
		"Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
		sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
		monospace;
}

.fade-in-section {
	opacity: 0;
	transform: translateY(20vh);
	visibility: hidden;
	transition: opacity 0.6s ease-out, transform 1.2s ease-out;
	will-change: opacity, visibility;
}
.fade-in-section.is-visible {
	opacity: 1;
	transform: none;
	visibility: visible;
}
.image {
	position: "relative";
	height: 100vh;
	width: 100%;
	@media (max-width: 818px) {
		height: 60vh;
	}
	@media (max-width: 670px) {
		height: 40vh;
	}
	@media (max-width: 375px) {
		height: 35vh;
	}
}

.content {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	position: absolute;
	height: 90vh;
	width: 100%;
	color: white;
	font-family: "Merriweather", serif;
	margin-top: -25vh;
	margin-left: 15%;
	@media (max-width: 1450px) {
		margin-top: -30vh;
		margin-left: 20%;
	}
	@media (max-width: 1450px) {
		margin-top: -35vh;
	}
	@media (max-width: 600px) {
		margin-top: -40vh;
	}
}

.social-links {
	display: flex;
	flex-direction: row;
	margin-left: 1%;
}

.each-link {
	margin: 1%;
}

.social-links a {
	text-decoration: none;
	display: inline-block;
}

.social-links a img {
	width: auto;
	height: 50px;
	display: block;
	@media (max-width: 819px) {
		height: 35px;
	}
	@media (max-width: 651px) {
		height: 25px;
	}
	@media (max-width: 426px) {
		height: 15px;
	}
}

@keyframes wiggle {
	0%,
	100% {
		transform: translateY(0);
	}
	50% {
		transform: translateY(20px);
	}
}

.arrow {
	display: flex;
	justify-content: center;
	position: absolute;
	bottom: -575px;
	width: 100%;
	animation: wiggle 2s infinite;
	transition: bottom 0.3s;

	@media (max-width: 1180px) {
		bottom: -475px;
	}
	@media (max-width: 863px) {
		bottom: -400px;
	}
	@media (max-width: 814px) {
		bottom: -430px;
	}
	@media (max-width: 765px) {
		bottom: -300px;
	}
	@media (max-width: 665px) {
		bottom: -230px;
	}
	@media (max-width: 445px) {
		bottom: -180px;
	}
	@media (max-width: 371px) {
		bottom: -160px;
	}
}

.arrow i {
	font-size: 4em;
	@media (max-width: 1180px) {
		font-size: 3.5em;
	}
	@media (max-width: 816px) {
		font-size: 2em;
	}
	color: white;
	text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.8);
}

.linkedin-logo {
	padding-top: 3%;
	padding-left: 12%;
}

.content h1 {
	font-family: "Oxygen", sans-serif;
	font-size: 4em;
	margin-top: 0;
	color: #fff;
	text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.8);
	@media (max-width: 818px) {
		text-align: center;
		font-size: 3em;
	}
	@media (max-width: 600px) {
		text-align: center;
		font-size: 1.4em;
	}
	@media (max-width: 400px) {
		text-align: center;
		font-size: 1.2em;
	}
}

.content h3 {
	font-size: 2em;
	font-style: italic;
	text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.8);
	@media (max-width: 818px) {
		text-align: center;
		font-size: 1.3em;
	}
	@media (max-width: 600px) {
		text-align: center;
		font-size: 1em;
	}
	@media (max-width: 400px) {
		text-align: center;
		font-size: 0.7em;
	}
}

.content p {
	font-size: 1.5em;
	font-style: italic;
	text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.8);
	margin: 0;
	@media (max-width: 818px) {
		text-align: center;
		font-size: 1em;
	}
	@media (max-width: 600px) {
		text-align: center;
		font-size: 0.8em;
	}
}
.about-me {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	background-color: #f0f0f0;
	padding: 20px;
	border-radius: 10px;
	box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.about-me h1 {
	font-family: "Lato", sans-serif;
	color: #333;
	margin-bottom: 10px;
	position: relative;
	@media (max-width: 568px) {
		font-size: 1em;
	}
}
.about-me h2 {
	font-family: "Lato", sans-serif;
	color: #333;
	margin-bottom: 10px;
	text-align: center;
	@media (max-width: 568px) {
		font-size: 0.8em;
	}
}

.about-me h1::before,
.about-me h1::after {
	content: "";
	position: absolute;
	top: 50%;
	width: 50px;
	height: 4px;
	background-color: #333;
}

.about-me h1::before {
	left: -60px;
}

.about-me h1::after {
	right: -60px;
}

.about-me p {
	font-weight: "bold";
	font-family: "Open Sans", sans-serif;
	color: #0f0e17;
	line-height: 1.6;
	text-align: center;
	padding-left: 20%;
	padding-right: 20%;
	@media (max-width: 568px) {
		font-size: 0.8em;
		padding-left: 10%;
		padding-right: 10%;
	}
}

.section-heading {
	font-size: 24px;
	color: #333;
	margin-bottom: 20px;
	margin-top: 50px;
	text-align: center;
	@media (max-width: 568px) {
		font-size: 0.8em;
		margin-top: 10px;
	}
}

.section-heading-tech {
	font-size: 24px;
	color: #fff;
	margin-bottom: 20px;
	margin-top: 50px;
	text-align: center;
}

.experience-item {
	background-color: #f9f9f9;
	border-radius: 8px;
	padding: 20px;
	margin: 0 auto;
	margin-bottom: 20px;
	box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
	@media (max-width: 950px) {
		width: 80%;
	}
	@media (max-width: 568px) {
		padding: 10px;
	}
}

.experience-title {
	font-size: 18px;
	color: #333;
	margin-bottom: 10px;
	@media (max-width: 568px) {
		font-size: 15px;
	}
}

.experience-date {
	font-size: 14px;
	color: #666;
	margin-bottom: 10px;
	@media (max-width: 568px) {
		font-size: 10px;
	}
}
.experience-place {
	font-size: 14px;
	color: #666;
	@media (max-width: 568px) {
		font-size: 10px;
	}
}

.responsibilities-list {
	list-style-type: none;
	padding: 0;
}

.responsibility-item {
	font-size: 14px;
	color: #0f0e17;
	margin-bottom: 10px;
	@media (max-width: 937px) {
		font-size: 12px;
	}
	@media (max-width: 568px) {
		font-size: 10px;
	}
}

.container {
	max-width: 800px;
	margin: 3% auto;
	@media (max-width: 950px) {
		width: 80%;
	}
	@media (max-width: 568px) {
		padding: 10px;
	}
}

.container-contact {
	background-color: #f0f0f0;
	padding: 20px;
	border-radius: 10px;
	box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
	text-align: center;
	margin: 3% auto;
}

.project-item {
	background-color: #f9f9f9;
	border-radius: 8px;
	padding: 20px;
	margin-bottom: 20px;
	box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
	@media (max-width: 937px) {
		font-size: 12px;
	}
	@media (max-width: 568px) {
		font-size: 10px;
	}
}

.project-title {
	font-size: 18px;
	color: #333;
	margin-bottom: 10px;
	@media (max-width: 568px) {
		font-size: 15px;
	}
}

.project-links a {
	margin-right: 10px;
	text-decoration: none;
	color: #007bff;
	font-weight: bold;
	@media (max-width: 568px) {
		font-size: 7px;
	}
}

.project-description {
	font-size: 14px;
	color: #0f0e17;
	margin-bottom: 10px;
	@media (max-width: 568px) {
		font-size: 10px;
	}
}

.interest-description {
	font-size: 14px;
	color: #0f0e17;
	margin-bottom: 10px;
	@media (max-width: 568px) {
		font-size: 10px;
	}
}

.interest-item {
	padding-top: 2%;
	background-color: #f9f9f9;
	border-radius: 8px;
	margin-bottom: 20px;
	box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
	padding-right: 10%;
	padding-left: 10%;
	padding-bottom: 5%;
	height: 90%;
	@media (max-width: 1100px) {
		margin: auto;
		margin-bottom: 15px;
		padding-right: 1%;
		padding-left: 1%;
		padding-bottom: 3%;
		padding-top: 1%;
		height: 90%;
		width: 70%;
	}
}

.interest-title {
	font-size: 18px;
	color: #333;
	margin-bottom: 10px;
	@media (max-width: 568px) {
		font-size: 15px;
	}
}
.interests-container {
	margin: 5% auto;
}

.volunteer-item {
	background-color: #f9f9f9;
	border-radius: 8px;
	padding: 20px;
	margin-bottom: 20px;
	box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.organization {
	font-size: 18px;
	color: #333;
	margin-bottom: 10px;
	@media (max-width: 568px) {
		font-size: 15px;
	}
}

.location,
.dates {
	font-size: 14px;
	color: #666;
	margin-bottom: 10px;
	@media (max-width: 568px) {
		font-size: 10px;
	}
}

.responsibilities-list {
	list-style-type: none;
	padding: 0;
}

.technical-skills-container {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	height: 50vh;
	width: 100%;
	margin: 3% auto;

	@media (max-width: 1020px) {
		height: 30vh;
		padding-bottom: 10%;
	}
	@media (max-width: 898px) {
		height: 60vh;
		padding-bottom: 25%;
	}
}

.skills-categories-container {
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 20px;
	margin: 3% auto;

	@media (max-width: 900px) {
		width: 100px;
		margin-left: auto;
		margin-right: auto;
	}
}

.skills-category {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 75%;
	padding: 20px;
	margin: 5%;
	background-color: #ffffff;
	border-radius: 10px;
	box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
	text-align: center;
	height: 70%;
	@media (max-width: 1020px) {
		padding-top: 10px;
		padding-bottom: 10px;
		margin: 2%;
	}
	@media (max-width: 898px) {
		margin-left: 7%;
	}
	@media (max-width: 600px) {
		height: 80%;
	}
}

.skills-category h3 {
	font-size: 24px;
	color: #333;
	margin-bottom: 15px;
	@media (max-width: 1020px) {
		font-size: 15px;
		margin-bottom: 8px;
	}
	@media (max-width: 600px) {
		font-size: 12px;
		margin-bottom: 6px;
	}
}

.skills-list {
	font-size: 16px;
	color: #0f0e17;
	margin-bottom: 10px;
	padding: 8px;
	background-color: #f2f2f2;
	border-radius: 6px;
	display: inline-block;
	@media (max-width: 1020px) {
		font-size: 10px;
		padding: 3px;
		margin-bottom: 5px;
	}
}

.skills-list:hover {
	background-color: #e0e0e0;
	transition: background-color 0.3s ease;
}

.contact-container {
	padding: 20px;
	box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
	border-radius: 10px;
	background-color: #f9f9f9;
	text-align: center;
	margin: 3% auto;
}

/* Contact Information */
.contact-info {
	font-size: 18px;
	line-height: 1.6;
	margin-bottom: 15px;
	@media (max-width: 817px) {
		font-size: 15px;
	}
	@media (max-width: 568px) {
		font-size: 11px;
	}
}

/* Links */
.contact-info a {
	color: #000; /* Fix color value */
	text-decoration: none;
	font-weight: bold;
	margin-left: 5px;
}

.experience-item {
	display: flex;
	align-items: flex-start;
	margin-bottom: 20px;
}

.company-logo {
	width: 50px;
	height: auto;
	margin-right: 10px;
	@media (max-width: 817px) {
		width: 40px;
	}
	@media (max-width: 568px) {
		width: 25px;
	}
}

.experience-details {
	flex: 1;
}
